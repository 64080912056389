import { HotlineDataType } from "components/LeafletMap/TrackpointControls/HotlineControl";
import { SortDirection } from "components/UI/SortByPicker";
import { DEFAULT_MAP_ID, MAP_SETTINGS } from "config/constants";
import { MapColor } from "config/enums/mapColors";
import { MapID } from "config/enums/mapIDs";
import { Overlay } from "config/enums/overlays";
import { Unit } from "config/enums/units";
import { TracksSortByOptions } from "containers/UI/LeftMenu/TracksMenu";
import { WaypointsSortByOptions } from "containers/UI/LeftMenu/WaypointsMenu";
import { StorageSize } from "hooks/useImageUpload";
import { Coords } from "types/app";
import { getQueryParam } from "utils/getQueryParam";
import { getAutomaticLanguage } from "utils/language/getAutomaticLanguage";
import { isValidMapID } from "utils/validators/isValidMapID";

/** Idea for refactor:
 * NonSaveable options should be moved to genericStore, and only saveable options be handled by this data store
 */

export type SortOptions<T> = {
  type: T;
  direction: SortDirection;
};

export interface NonSaveableOptions {
  /** Controls whether public tracks should be displayed on map */
  showPublicTracks: boolean;
  /** Controls whether map legend is open or not */
  isLegendOpen: boolean;
  /** Controls whether left menu is open or not */
  isLeftMenuOpen: boolean;
  hotlineType: HotlineDataType;
  allowPremiumFeatures: boolean;
  storageSize?: StorageSize;
}

export interface SaveableOptions {
  units: Unit;
  /**
   * Selected overlays
   */
  mapOverlays: Overlay[];
  /** BCP-47 language tag */
  language: string;
  /**
   * Controls map color for Refill map (MapID.refill)
   */
  mapColor: MapColor;
  mapType: MapID;
  /**
   * User's saved view zoom (will be restored on login if autoSaveView === true)
   */
  viewZoom: number;
  /**
   * Controls whether view should be automatically saved and also restored after login
   */
  autoSaveView: boolean;
  /**
   * User's saved view center (will be restored on login if autoSaveView === true)
   */
  viewCenter: Coords;
  /** label count percentage (0-100) */
  mapLabels: number;
  sortTracks: SortOptions<TracksSortByOptions>;
  sortWaypoints: SortOptions<WaypointsSortByOptions>;
  colorScheme: string;
  receiveNewsletter: boolean;
}

const basemapQueryParam = getQueryParam("basemap") ?? DEFAULT_MAP_ID;
const basemapID = isValidMapID(Number(basemapQueryParam))
  ? Number(basemapQueryParam)
  : DEFAULT_MAP_ID;

export const initialOptionsState: SaveableOptions & NonSaveableOptions = {
  showPublicTracks: false,
  units: Unit.metric,
  mapOverlays: [Overlay.bikeTrails, Overlay.hikeWaymarked],
  language: getAutomaticLanguage() ?? "en",
  mapColor: MapColor.black,
  mapType: basemapID,
  isLegendOpen: false,
  viewZoom: MAP_SETTINGS.zoom || 3,
  autoSaveView: true,
  viewCenter: MAP_SETTINGS.center,
  isLeftMenuOpen: false,
  mapLabels: 100,
  hotlineType: "none",
  sortTracks: { type: "name", direction: "asc" },
  sortWaypoints: { type: "name", direction: "asc" },
  colorScheme: "light",
  receiveNewsletter: false,
  allowPremiumFeatures: false,
  storageSize: undefined,
};
